export const NBA_RESPONSE = {
  data: [
    {
      id: 15907612,
      date: "2024-11-13",
      season: 2024,
      status: "Final",
      period: 4,
      time: "Final",
      postseason: false,
      home_team_score: 127,
      visitor_team_score: 104,
      home_team: {
        id: 26,
        conference: "West",
        division: "Pacific",
        city: "Sacramento",
        name: "Kings",
        full_name: "Sacramento Kings",
        abbreviation: "SAC",
      },
      visitor_team: {
        id: 24,
        conference: "West",
        division: "Pacific",
        city: "Phoenix",
        name: "Suns",
        full_name: "Phoenix Suns",
        abbreviation: "PHX",
      },
    },
    {
      id: 15907608,
      date: "2024-11-13",
      season: 2024,
      status: "Final",
      period: 5,
      time: "Final",
      postseason: false,
      home_team_score: 127,
      visitor_team_score: 120,
      home_team: {
        id: 17,
        conference: "East",
        division: "Central",
        city: "Milwaukee",
        name: "Bucks",
        full_name: "Milwaukee Bucks",
        abbreviation: "MIL",
      },
      visitor_team: {
        id: 9,
        conference: "East",
        division: "Central",
        city: "Detroit",
        name: "Pistons",
        full_name: "Detroit Pistons",
        abbreviation: "DET",
      },
    },
    {
      id: 15907609,
      date: "2024-11-13",
      season: 2024,
      status: "Final",
      period: 4,
      time: "Final",
      postseason: false,
      home_team_score: 139,
      visitor_team_score: 130,
      home_team: {
        id: 27,
        conference: "West",
        division: "Southwest",
        city: "San Antonio",
        name: "Spurs",
        full_name: "San Antonio Spurs",
        abbreviation: "SAS",
      },
      visitor_team: {
        id: 30,
        conference: "East",
        division: "Southeast",
        city: "Washington",
        name: "Wizards",
        full_name: "Washington Wizards",
        abbreviation: "WAS",
      },
    },
    {
      id: 15907610,
      date: "2024-11-13",
      season: 2024,
      status: "Final",
      period: 4,
      time: "Final",
      postseason: false,
      home_team_score: 128,
      visitor_team_score: 123,
      home_team: {
        id: 14,
        conference: "West",
        division: "Pacific",
        city: "Los Angeles",
        name: "Lakers",
        full_name: "Los Angeles Lakers",
        abbreviation: "LAL",
      },
      visitor_team: {
        id: 15,
        conference: "West",
        division: "Southwest",
        city: "Memphis",
        name: "Grizzlies",
        full_name: "Memphis Grizzlies",
        abbreviation: "MEM",
      },
    },
    {
      id: 15907611,
      date: "2024-11-13",
      season: 2024,
      status: "Final",
      period: 4,
      time: "Final",
      postseason: false,
      home_team_score: 106,
      visitor_team_score: 98,
      home_team: {
        id: 25,
        conference: "West",
        division: "Northwest",
        city: "Portland",
        name: "Trail Blazers",
        full_name: "Portland Trail Blazers",
        abbreviation: "POR",
      },
      visitor_team: {
        id: 18,
        conference: "West",
        division: "Northwest",
        city: "Minnesota",
        name: "Timberwolves",
        full_name: "Minnesota Timberwolves",
        abbreviation: "MIN",
      },
    },
    {
      id: 15907602,
      date: "2024-11-13",
      season: 2024,
      status: "Final",
      period: 4,
      time: "Final",
      postseason: false,
      home_team_score: 94,
      visitor_team_score: 90,
      home_team: {
        id: 22,
        conference: "East",
        division: "Southeast",
        city: "Orlando",
        name: "Magic",
        full_name: "Orlando Magic",
        abbreviation: "ORL",
      },
      visitor_team: {
        id: 12,
        conference: "East",
        division: "Central",
        city: "Indiana",
        name: "Pacers",
        full_name: "Indiana Pacers",
        abbreviation: "IND",
      },
    },
    {
      id: 15907603,
      date: "2024-11-13",
      season: 2024,
      status: "Final",
      period: 4,
      time: "Final",
      postseason: false,
      home_team_score: 114,
      visitor_team_score: 139,
      home_team: {
        id: 3,
        conference: "East",
        division: "Atlantic",
        city: "Brooklyn",
        name: "Nets",
        full_name: "Brooklyn Nets",
        abbreviation: "BKN",
      },
      visitor_team: {
        id: 2,
        conference: "East",
        division: "Atlantic",
        city: "Boston",
        name: "Celtics",
        full_name: "Boston Celtics",
        abbreviation: "BOS",
      },
    },
    {
      id: 15907604,
      date: "2024-11-13",
      season: 2024,
      status: "Final",
      period: 4,
      time: "Final",
      postseason: false,
      home_team_score: 123,
      visitor_team_score: 124,
      home_team: {
        id: 20,
        conference: "East",
        division: "Atlantic",
        city: "New York",
        name: "Knicks",
        full_name: "New York Knicks",
        abbreviation: "NYK",
      },
      visitor_team: {
        id: 5,
        conference: "East",
        division: "Central",
        city: "Chicago",
        name: "Bulls",
        full_name: "Chicago Bulls",
        abbreviation: "CHI",
      },
    },
    {
      id: 15907605,
      date: "2024-11-13",
      season: 2024,
      status: "Final",
      period: 4,
      time: "Final",
      postseason: false,
      home_team_score: 106,
      visitor_team_score: 114,
      home_team: {
        id: 23,
        conference: "East",
        division: "Atlantic",
        city: "Philadelphia",
        name: "76ers",
        full_name: "Philadelphia 76ers",
        abbreviation: "PHI",
      },
      visitor_team: {
        id: 6,
        conference: "East",
        division: "Central",
        city: "Cleveland",
        name: "Cavaliers",
        full_name: "Cleveland Cavaliers",
        abbreviation: "CLE",
      },
    },
    {
      id: 15907606,
      date: "2024-11-13",
      season: 2024,
      status: "Final",
      period: 4,
      time: "Final",
      postseason: false,
      home_team_score: 106,
      visitor_team_score: 88,
      home_team: {
        id: 21,
        conference: "West",
        division: "Northwest",
        city: "Oklahoma City",
        name: "Thunder",
        full_name: "Oklahoma City Thunder",
        abbreviation: "OKC",
      },
      visitor_team: {
        id: 19,
        conference: "West",
        division: "Southwest",
        city: "New Orleans",
        name: "Pelicans",
        full_name: "New Orleans Pelicans",
        abbreviation: "NOP",
      },
    },
    {
      id: 15907607,
      date: "2024-11-13",
      season: 2024,
      status: "Final",
      period: 4,
      time: "Final",
      postseason: false,
      home_team_score: 111,
      visitor_team_score: 103,
      home_team: {
        id: 11,
        conference: "West",
        division: "Southwest",
        city: "Houston",
        name: "Rockets",
        full_name: "Houston Rockets",
        abbreviation: "HOU",
      },
      visitor_team: {
        id: 13,
        conference: "West",
        division: "Pacific",
        city: "LA",
        name: "Clippers",
        full_name: "LA Clippers",
        abbreviation: "LAC",
      },
    },
  ],
  meta: {
    per_page: 25,
  },
};

export const NFL_RESPONSE = {
  data: [
    {
      id: 7001,
      visitor_team: {
        id: 6,
        conference: "AFC",
        division: "NORTH",
        location: "Baltimore",
        name: "Ravens",
        full_name: "Baltimore Ravens",
        abbreviation: "BAL",
      },
      home_team: {
        id: 14,
        conference: "AFC",
        division: "WEST",
        location: "Kansas City",
        name: "Chiefs",
        full_name: "Kansas City Chiefs",
        abbreviation: "KC",
      },
      summary:
        "Chiefs hold off Ravens 27-20 when review overturns TD on final play of NFL's season opener",
      venue: "GEHA Field at Arrowhead Stadium",
      week: 1,
      date: "2024-09-06T00:20:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 27,
      home_team_q1: 7,
      home_team_q2: 6,
      home_team_q3: 7,
      home_team_q4: 7,
      home_team_ot: null,
      visitor_team_score: 20,
      visitor_team_q1: 7,
      visitor_team_q2: 3,
      visitor_team_q3: null,
      visitor_team_q4: 10,
      visitor_team_ot: null,
    },
    {
      id: 7002,
      visitor_team: {
        id: 22,
        conference: "NFC",
        division: "NORTH",
        location: "Green Bay",
        name: "Packers",
        full_name: "Green Bay Packers",
        abbreviation: "GB",
      },
      home_team: {
        id: 18,
        conference: "NFC",
        division: "EAST",
        location: "Philadelphia",
        name: "Eagles",
        full_name: "Philadelphia Eagles",
        abbreviation: "PHI",
      },
      summary:
        "Barkley scores 3 TDs as Eagles beat Packers 34-29 in Brazil. Packers' Love injured in final minute",
      venue: "Corinthians Arena",
      week: 1,
      date: "2024-09-07T00:15:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 34,
      home_team_q1: null,
      home_team_q2: 17,
      home_team_q3: 14,
      home_team_q4: 3,
      home_team_ot: null,
      visitor_team_score: 29,
      visitor_team_q1: 6,
      visitor_team_q2: 13,
      visitor_team_q3: 7,
      visitor_team_q4: 3,
      visitor_team_ot: null,
    },
    {
      id: 7003,
      visitor_team: {
        id: 7,
        conference: "AFC",
        division: "NORTH",
        location: "Pittsburgh",
        name: "Steelers",
        full_name: "Pittsburgh Steelers",
        abbreviation: "PIT",
      },
      home_team: {
        id: 27,
        conference: "NFC",
        division: "SOUTH",
        location: "Atlanta",
        name: "Falcons",
        full_name: "Atlanta Falcons",
        abbreviation: "ATL",
      },
      summary:
        "Fields steps in for Wilson, Boswell boots 6 field goals to lead Steelers past Falcons 18-10",
      venue: "Mercedes-Benz Stadium",
      week: 1,
      date: "2024-09-08T17:00:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 10,
      home_team_q1: 3,
      home_team_q2: 7,
      home_team_q3: null,
      home_team_q4: null,
      home_team_ot: null,
      visitor_team_score: 18,
      visitor_team_q1: 3,
      visitor_team_q2: 6,
      visitor_team_q3: 6,
      visitor_team_q4: 3,
      visitor_team_ot: null,
    },
    {
      id: 7004,
      visitor_team: {
        id: 33,
        conference: "NFC",
        division: "WEST",
        location: "Arizona",
        name: "Cardinals",
        full_name: "Arizona Cardinals",
        abbreviation: "ARI",
      },
      home_team: {
        id: 3,
        conference: "AFC",
        division: "EAST",
        location: "Buffalo",
        name: "Bills",
        full_name: "Buffalo Bills",
        abbreviation: "BUF",
      },
      summary:
        "Josh Allen throws 2 TD passes and runs for 2 others as Bills rally for 34-28 win over Cardinals",
      venue: "Highmark Stadium",
      week: 1,
      date: "2024-09-08T17:00:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 34,
      home_team_q1: null,
      home_team_q2: 10,
      home_team_q3: 14,
      home_team_q4: 10,
      home_team_ot: null,
      visitor_team_score: 28,
      visitor_team_q1: 7,
      visitor_team_q2: 10,
      visitor_team_q3: null,
      visitor_team_q4: 11,
      visitor_team_ot: null,
    },
    {
      id: 7005,
      visitor_team: {
        id: 11,
        conference: "AFC",
        division: "SOUTH",
        location: "Tennessee",
        name: "Titans",
        full_name: "Tennessee Titans",
        abbreviation: "TEN",
      },
      home_team: {
        id: 24,
        conference: "NFC",
        division: "NORTH",
        location: "Chicago",
        name: "Bears",
        full_name: "Chicago Bears",
        abbreviation: "CHI",
      },
      summary:
        "Caleb Williams has a rough debut but gets bailed out by Bears' defense in 24-17 win over Titans",
      venue: "Soldier Field",
      week: 1,
      date: "2024-09-08T17:00:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 24,
      home_team_q1: null,
      home_team_q2: 3,
      home_team_q3: 7,
      home_team_q4: 14,
      home_team_ot: null,
      visitor_team_score: 17,
      visitor_team_q1: 7,
      visitor_team_q2: 10,
      visitor_team_q3: null,
      visitor_team_q4: null,
      visitor_team_ot: null,
    },
    {
      id: 7006,
      visitor_team: {
        id: 1,
        conference: "AFC",
        division: "EAST",
        location: "New England",
        name: "Patriots",
        full_name: "New England Patriots",
        abbreviation: "NE",
      },
      home_team: {
        id: 9,
        conference: "AFC",
        division: "NORTH",
        location: "Cincinnati",
        name: "Bengals",
        full_name: "Cincinnati Bengals",
        abbreviation: "CIN",
      },
      summary:
        "Patriots win Jerod Mayo's debut as coach, beating mistake-prone Bengals 16-10",
      venue: "Paycor Stadium",
      week: 1,
      date: "2024-09-08T17:00:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 10,
      home_team_q1: null,
      home_team_q2: null,
      home_team_q3: 7,
      home_team_q4: 3,
      home_team_ot: null,
      visitor_team_score: 16,
      visitor_team_q1: null,
      visitor_team_q2: 10,
      visitor_team_q3: 3,
      visitor_team_q4: 3,
      visitor_team_ot: null,
    },
    {
      id: 7007,
      visitor_team: {
        id: 10,
        conference: "AFC",
        division: "SOUTH",
        location: "Houston",
        name: "Texans",
        full_name: "Houston Texans",
        abbreviation: "HOU",
      },
      home_team: {
        id: 12,
        conference: "AFC",
        division: "SOUTH",
        location: "Indianapolis",
        name: "Colts",
        full_name: "Indianapolis Colts",
        abbreviation: "IND",
      },
      summary:
        "Stefon Diggs, Joe Mixon combine to lead Texans past Colts 29-27 in season opener",
      venue: "Lucas Oil Stadium",
      week: 1,
      date: "2024-09-08T17:00:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 27,
      home_team_q1: 7,
      home_team_q2: null,
      home_team_q3: 6,
      home_team_q4: 14,
      home_team_ot: null,
      visitor_team_score: 29,
      visitor_team_q1: 6,
      visitor_team_q2: 6,
      visitor_team_q3: 3,
      visitor_team_q4: 14,
      visitor_team_ot: null,
    },
    {
      id: 7008,
      visitor_team: {
        id: 13,
        conference: "AFC",
        division: "SOUTH",
        location: "Jacksonville",
        name: "Jaguars",
        full_name: "Jacksonville Jaguars",
        abbreviation: "JAX",
      },
      home_team: {
        id: 5,
        conference: "AFC",
        division: "EAST",
        location: "Miami",
        name: "Dolphins",
        full_name: "Miami Dolphins",
        abbreviation: "MIA",
      },
      summary:
        "Jason Sanders makes 52-yard field goal as time expires and Dolphins rally past Jaguars 20-17",
      venue: "Hard Rock Stadium",
      week: 1,
      date: "2024-09-08T17:00:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 20,
      home_team_q1: null,
      home_team_q2: 7,
      home_team_q3: 7,
      home_team_q4: 6,
      home_team_ot: null,
      visitor_team_score: 17,
      visitor_team_q1: 7,
      visitor_team_q2: 10,
      visitor_team_q3: null,
      visitor_team_q4: null,
      visitor_team_ot: null,
    },
    {
      id: 7009,
      visitor_team: {
        id: 29,
        conference: "NFC",
        division: "SOUTH",
        location: "Carolina",
        name: "Panthers",
        full_name: "Carolina Panthers",
        abbreviation: "CAR",
      },
      home_team: {
        id: 26,
        conference: "NFC",
        division: "SOUTH",
        location: "New Orleans",
        name: "Saints",
        full_name: "New Orleans Saints",
        abbreviation: "NO",
      },
      summary:
        "Derek Carr and the Saints rout the Panthers 47-10 in Klint Kubiak's play-calling debut",
      venue: "Caesars Superdome",
      week: 1,
      date: "2024-09-08T17:00:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 47,
      home_team_q1: 17,
      home_team_q2: 13,
      home_team_q3: 7,
      home_team_q4: 10,
      home_team_ot: null,
      visitor_team_score: 10,
      visitor_team_q1: null,
      visitor_team_q2: 3,
      visitor_team_q3: 7,
      visitor_team_q4: null,
      visitor_team_ot: null,
    },
    {
      id: 7010,
      visitor_team: {
        id: 23,
        conference: "NFC",
        division: "NORTH",
        location: "Minnesota",
        name: "Vikings",
        full_name: "Minnesota Vikings",
        abbreviation: "MIN",
      },
      home_team: {
        id: 20,
        conference: "NFC",
        division: "EAST",
        location: "New York",
        name: "Giants",
        full_name: "New York Giants",
        abbreviation: "NYG",
      },
      summary:
        "Darnold throws 2 TD passes and Van Ginkel scores on a 1-handed INT as Vikings easily top Giants 28-6",
      venue: "MetLife Stadium",
      week: 1,
      date: "2024-09-08T17:00:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 6,
      home_team_q1: 3,
      home_team_q2: null,
      home_team_q3: 3,
      home_team_q4: null,
      home_team_ot: null,
      visitor_team_score: 28,
      visitor_team_q1: 7,
      visitor_team_q2: 7,
      visitor_team_q3: 14,
      visitor_team_q4: null,
      visitor_team_ot: null,
    },
    {
      id: 7011,
      visitor_team: {
        id: 16,
        conference: "AFC",
        division: "WEST",
        location: "Las Vegas",
        name: "Raiders",
        full_name: "Las Vegas Raiders",
        abbreviation: "LV",
      },
      home_team: {
        id: 17,
        conference: "AFC",
        division: "WEST",
        location: "Los Angeles",
        name: "Chargers",
        full_name: "Los Angeles Chargers",
        abbreviation: "LAC",
      },
      summary:
        "J.K. Dobbins rushes for 135 yards as the Chargers beat the Raiders 22-10 in Jim Harbaugh's debut",
      venue: "SoFi Stadium",
      week: 1,
      date: "2024-09-08T20:05:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 22,
      home_team_q1: 3,
      home_team_q2: 3,
      home_team_q3: 3,
      home_team_q4: 13,
      home_team_ot: null,
      visitor_team_score: 10,
      visitor_team_q1: 7,
      visitor_team_q2: null,
      visitor_team_q3: null,
      visitor_team_q4: 3,
      visitor_team_ot: null,
    },
    {
      id: 7012,
      visitor_team: {
        id: 15,
        conference: "AFC",
        division: "WEST",
        location: "Denver",
        name: "Broncos",
        full_name: "Denver Broncos",
        abbreviation: "DEN",
      },
      home_team: {
        id: 31,
        conference: "NFC",
        division: "WEST",
        location: "Seattle",
        name: "Seahawks",
        full_name: "Seattle Seahawks",
        abbreviation: "SEA",
      },
      summary:
        "Kenneth Walker III sparks Seahawks rally as Seattle tops Denver 26-20 in Mike Macdonald's debut",
      venue: "Lumen Field",
      week: 1,
      date: "2024-09-08T20:05:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 26,
      home_team_q1: 3,
      home_team_q2: 6,
      home_team_q3: 10,
      home_team_q4: 7,
      home_team_ot: null,
      visitor_team_score: 20,
      visitor_team_q1: 3,
      visitor_team_q2: 10,
      visitor_team_q3: null,
      visitor_team_q4: 7,
      visitor_team_ot: null,
    },
    {
      id: 7013,
      visitor_team: {
        id: 19,
        conference: "NFC",
        division: "EAST",
        location: "Dallas",
        name: "Cowboys",
        full_name: "Dallas Cowboys",
        abbreviation: "DAL",
      },
      home_team: {
        id: 8,
        conference: "AFC",
        division: "NORTH",
        location: "Cleveland",
        name: "Browns",
        full_name: "Cleveland Browns",
        abbreviation: "CLE",
      },
      summary:
        "Dak Prescott leads Cowboys to 33-17 romp over Browns in opener after getting new 4-year contract",
      venue: "Huntington Bank Field",
      week: 1,
      date: "2024-09-08T20:25:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 17,
      home_team_q1: 3,
      home_team_q2: null,
      home_team_q3: 7,
      home_team_q4: 7,
      home_team_ot: null,
      visitor_team_score: 33,
      visitor_team_q1: 7,
      visitor_team_q2: 13,
      visitor_team_q3: 10,
      visitor_team_q4: 3,
      visitor_team_ot: null,
    },
    {
      id: 7014,
      visitor_team: {
        id: 21,
        conference: "NFC",
        division: "EAST",
        location: "Washington",
        name: "Commanders",
        full_name: "Washington Commanders",
        abbreviation: "WSH",
      },
      home_team: {
        id: 28,
        conference: "NFC",
        division: "SOUTH",
        location: "Tampa Bay",
        name: "Buccaneers",
        full_name: "Tampa Bay Buccaneers",
        abbreviation: "TB",
      },
      summary:
        "Baker Mayfield and Buccaneers rout Commanders 37-20 to spoil Jayden Daniels' debut",
      venue: "Raymond James Stadium",
      week: 1,
      date: "2024-09-08T20:25:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 37,
      home_team_q1: 6,
      home_team_q2: 10,
      home_team_q3: 7,
      home_team_q4: 14,
      home_team_ot: null,
      visitor_team_score: 20,
      visitor_team_q1: null,
      visitor_team_q2: 7,
      visitor_team_q3: 7,
      visitor_team_q4: 6,
      visitor_team_ot: null,
    },
    {
      id: 7015,
      visitor_team: {
        id: 32,
        conference: "NFC",
        division: "WEST",
        location: "Los Angeles",
        name: "Rams",
        full_name: "Los Angeles Rams",
        abbreviation: "LAR",
      },
      home_team: {
        id: 25,
        conference: "NFC",
        division: "NORTH",
        location: "Detroit",
        name: "Lions",
        full_name: "Detroit Lions",
        abbreviation: "DET",
      },
      summary:
        "Montgomery's 1-yard touchdown run in OT lifts Lions to 26-20 win over Rams",
      venue: "Ford Field",
      week: 1,
      date: "2024-09-09T00:20:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final/OT",
      home_team_score: 26,
      home_team_q1: null,
      home_team_q2: 10,
      home_team_q3: 7,
      home_team_q4: 3,
      home_team_ot: 6,
      visitor_team_score: 20,
      visitor_team_q1: 3,
      visitor_team_q2: null,
      visitor_team_q3: 7,
      visitor_team_q4: 10,
      visitor_team_ot: null,
    },
    {
      id: 7016,
      visitor_team: {
        id: 4,
        conference: "AFC",
        division: "EAST",
        location: "New York",
        name: "Jets",
        full_name: "New York Jets",
        abbreviation: "NYJ",
      },
      home_team: {
        id: 30,
        conference: "NFC",
        division: "WEST",
        location: "San Francisco",
        name: "49ers",
        full_name: "San Francisco 49ers",
        abbreviation: "SF",
      },
      summary:
        "The 49ers spoil Aaron Rodgers' return with a 32-19 win over the Jets",
      venue: "Levi's Stadium",
      week: 1,
      date: "2024-09-10T00:20:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 32,
      home_team_q1: 3,
      home_team_q2: 13,
      home_team_q3: 10,
      home_team_q4: 6,
      home_team_ot: null,
      visitor_team_score: 19,
      visitor_team_q1: 7,
      visitor_team_q2: null,
      visitor_team_q3: 6,
      visitor_team_q4: 6,
      visitor_team_ot: null,
    },
    {
      id: 7017,
      visitor_team: {
        id: 3,
        conference: "AFC",
        division: "EAST",
        location: "Buffalo",
        name: "Bills",
        full_name: "Buffalo Bills",
        abbreviation: "BUF",
      },
      home_team: {
        id: 5,
        conference: "AFC",
        division: "EAST",
        location: "Miami",
        name: "Dolphins",
        full_name: "Miami Dolphins",
        abbreviation: "MIA",
      },
      summary:
        "Cook scores 3 TDs to help Bills rout Dolphins 31-10, Tagovailoa leaves with concussion",
      venue: "Hard Rock Stadium",
      week: 2,
      date: "2024-09-13T00:15:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 10,
      home_team_q1: 7,
      home_team_q2: 3,
      home_team_q3: null,
      home_team_q4: null,
      home_team_ot: null,
      visitor_team_score: 31,
      visitor_team_q1: 7,
      visitor_team_q2: 17,
      visitor_team_q3: 7,
      visitor_team_q4: null,
      visitor_team_ot: null,
    },
    {
      id: 7022,
      visitor_team: {
        id: 30,
        conference: "NFC",
        division: "WEST",
        location: "San Francisco",
        name: "49ers",
        full_name: "San Francisco 49ers",
        abbreviation: "SF",
      },
      home_team: {
        id: 23,
        conference: "NFC",
        division: "NORTH",
        location: "Minnesota",
        name: "Vikings",
        full_name: "Minnesota Vikings",
        abbreviation: "MIN",
      },
      summary: null,
      venue: "U.S. Bank Stadium",
      week: 2,
      date: "2024-09-15T17:00:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 23,
      home_team_q1: 3,
      home_team_q2: 10,
      home_team_q3: 7,
      home_team_q4: 3,
      home_team_ot: null,
      visitor_team_score: 17,
      visitor_team_q1: null,
      visitor_team_q2: 7,
      visitor_team_q3: null,
      visitor_team_q4: 10,
      visitor_team_ot: null,
    },
    {
      id: 7026,
      visitor_team: {
        id: 8,
        conference: "AFC",
        division: "NORTH",
        location: "Cleveland",
        name: "Browns",
        full_name: "Cleveland Browns",
        abbreviation: "CLE",
      },
      home_team: {
        id: 13,
        conference: "AFC",
        division: "SOUTH",
        location: "Jacksonville",
        name: "Jaguars",
        full_name: "Jacksonville Jaguars",
        abbreviation: "JAX",
      },
      summary: null,
      venue: "EverBank Stadium",
      week: 2,
      date: "2024-09-15T17:00:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 13,
      home_team_q1: null,
      home_team_q2: 3,
      home_team_q3: 7,
      home_team_q4: 3,
      home_team_ot: null,
      visitor_team_score: 18,
      visitor_team_q1: 7,
      visitor_team_q2: 6,
      visitor_team_q3: 3,
      visitor_team_q4: 2,
      visitor_team_ot: null,
    },
    {
      id: 7027,
      visitor_team: {
        id: 16,
        conference: "AFC",
        division: "WEST",
        location: "Las Vegas",
        name: "Raiders",
        full_name: "Las Vegas Raiders",
        abbreviation: "LV",
      },
      home_team: {
        id: 6,
        conference: "AFC",
        division: "NORTH",
        location: "Baltimore",
        name: "Ravens",
        full_name: "Baltimore Ravens",
        abbreviation: "BAL",
      },
      summary: null,
      venue: "M&T Bank Stadium",
      week: 2,
      date: "2024-09-15T17:00:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 23,
      home_team_q1: 3,
      home_team_q2: 6,
      home_team_q3: 7,
      home_team_q4: 7,
      home_team_ot: null,
      visitor_team_score: 26,
      visitor_team_q1: null,
      visitor_team_q2: 6,
      visitor_team_q3: 7,
      visitor_team_q4: 13,
      visitor_team_ot: null,
    },
    {
      id: 7018,
      visitor_team: {
        id: 26,
        conference: "NFC",
        division: "SOUTH",
        location: "New Orleans",
        name: "Saints",
        full_name: "New Orleans Saints",
        abbreviation: "NO",
      },
      home_team: {
        id: 19,
        conference: "NFC",
        division: "EAST",
        location: "Dallas",
        name: "Cowboys",
        full_name: "Dallas Cowboys",
        abbreviation: "DAL",
      },
      summary: null,
      venue: "AT&T Stadium",
      week: 2,
      date: "2024-09-15T17:00:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 19,
      home_team_q1: 3,
      home_team_q2: 13,
      home_team_q3: 3,
      home_team_q4: null,
      home_team_ot: null,
      visitor_team_score: 44,
      visitor_team_q1: 14,
      visitor_team_q2: 21,
      visitor_team_q3: 6,
      visitor_team_q4: 3,
      visitor_team_ot: null,
    },
    {
      id: 7019,
      visitor_team: {
        id: 28,
        conference: "NFC",
        division: "SOUTH",
        location: "Tampa Bay",
        name: "Buccaneers",
        full_name: "Tampa Bay Buccaneers",
        abbreviation: "TB",
      },
      home_team: {
        id: 25,
        conference: "NFC",
        division: "NORTH",
        location: "Detroit",
        name: "Lions",
        full_name: "Detroit Lions",
        abbreviation: "DET",
      },
      summary: null,
      venue: "Ford Field",
      week: 2,
      date: "2024-09-15T17:00:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 16,
      home_team_q1: 3,
      home_team_q2: 3,
      home_team_q3: 10,
      home_team_q4: null,
      home_team_ot: null,
      visitor_team_score: 20,
      visitor_team_q1: 6,
      visitor_team_q2: 7,
      visitor_team_q3: 7,
      visitor_team_q4: null,
      visitor_team_ot: null,
    },
    {
      id: 7020,
      visitor_team: {
        id: 12,
        conference: "AFC",
        division: "SOUTH",
        location: "Indianapolis",
        name: "Colts",
        full_name: "Indianapolis Colts",
        abbreviation: "IND",
      },
      home_team: {
        id: 22,
        conference: "NFC",
        division: "NORTH",
        location: "Green Bay",
        name: "Packers",
        full_name: "Green Bay Packers",
        abbreviation: "GB",
      },
      summary: null,
      venue: "Lambeau Field",
      week: 2,
      date: "2024-09-15T17:00:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 16,
      home_team_q1: 10,
      home_team_q2: null,
      home_team_q3: 3,
      home_team_q4: 3,
      home_team_ot: null,
      visitor_team_score: 10,
      visitor_team_q1: null,
      visitor_team_q2: null,
      visitor_team_q3: 3,
      visitor_team_q4: 7,
      visitor_team_ot: null,
    },
    {
      id: 7021,
      visitor_team: {
        id: 4,
        conference: "AFC",
        division: "EAST",
        location: "New York",
        name: "Jets",
        full_name: "New York Jets",
        abbreviation: "NYJ",
      },
      home_team: {
        id: 11,
        conference: "AFC",
        division: "SOUTH",
        location: "Tennessee",
        name: "Titans",
        full_name: "Tennessee Titans",
        abbreviation: "TEN",
      },
      summary: null,
      venue: "Nissan Stadium",
      week: 2,
      date: "2024-09-15T17:00:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final",
      home_team_score: 17,
      home_team_q1: 7,
      home_team_q2: 3,
      home_team_q3: 7,
      home_team_q4: null,
      home_team_ot: null,
      visitor_team_score: 24,
      visitor_team_q1: null,
      visitor_team_q2: 7,
      visitor_team_q3: 10,
      visitor_team_q4: 7,
      visitor_team_ot: null,
    },
    {
      id: 7023,
      visitor_team: {
        id: 31,
        conference: "NFC",
        division: "WEST",
        location: "Seattle",
        name: "Seahawks",
        full_name: "Seattle Seahawks",
        abbreviation: "SEA",
      },
      home_team: {
        id: 1,
        conference: "AFC",
        division: "EAST",
        location: "New England",
        name: "Patriots",
        full_name: "New England Patriots",
        abbreviation: "NE",
      },
      summary: null,
      venue: "Gillette Stadium",
      week: 2,
      date: "2024-09-15T17:00:00.000Z",
      season: 2024,
      postseason: false,
      status: "Final/OT",
      home_team_score: 20,
      home_team_q1: 7,
      home_team_q2: 6,
      home_team_q3: null,
      home_team_q4: 7,
      home_team_ot: null,
      visitor_team_score: 23,
      visitor_team_q1: 7,
      visitor_team_q2: 10,
      visitor_team_q3: null,
      visitor_team_q4: 3,
      visitor_team_ot: 3,
    },
  ],
  meta: {
    next_cursor: 7023,
    per_page: 25,
  },
};

export const MLB_RESPONSE = {
  data: [
    {
      id: 1966,
      home_team_name: "Houston Astros",
      away_team_name: "New York Yankees",
      home_team: {
        id: 11,
        slug: "houston-astros",
        abbreviation: "HOU",
        display_name: "Houston Astros",
        short_display_name: "Astros",
        name: "Astros",
        location: "Houston",
        league: "American",
        division: "West",
      },
      away_team: {
        id: 19,
        slug: "new-york-yankees",
        abbreviation: "NYY",
        display_name: "New York Yankees",
        short_display_name: "Yankees",
        name: "Yankees",
        location: "New York",
        league: "American",
        division: "East",
      },
      season: 2024,
      postseason: false,
      date: "2024-03-28T20:10:00.000Z",
      home_team_data: {
        hits: 13,
        runs: 4,
        errors: 0,
        inning_scores: [3, 1, 0, 0, 0, 0, 0, 0, 0],
      },
      away_team_data: {
        hits: 8,
        runs: 5,
        errors: 0,
        inning_scores: [0, 0, 0, 0, 3, 1, 1, 0, 0],
      },
      venue: "Minute Maid Park",
      attendance: 42642,
      conference_play: false,
      status: "STATUS_FINAL",
      period: 9,
      clock: 0,
      display_clock: "0:00",
      scoring_summary: [
        {
          play: "McCormick singled to center, Alvarez scored and Tucker scored, J. Abreu to third.",
          inning: "bottom",
          period: "1st",
          away_score: 0,
          home_score: 2,
        },
        {
          play: "Diaz singled to right, J. Abreu scored, McCormick to second.",
          inning: "bottom",
          period: "1st",
          away_score: 0,
          home_score: 3,
        },
        {
          play: "Meyers homered to left (415 feet).",
          inning: "bottom",
          period: "2nd",
          away_score: 0,
          home_score: 4,
        },
        {
          play: "Soto singled to right, Trevino scored, Torres to second, Cabrera to third.",
          inning: "top",
          period: "5th",
          away_score: 1,
          home_score: 4,
        },
        {
          play: "Rizzo hit by pitch, Cabrera scored, Soto to second, Torres to third.",
          inning: "top",
          period: "5th",
          away_score: 2,
          home_score: 4,
        },
        {
          play: "Volpe walked, Torres scored, Rizzo to second, Soto to third.",
          inning: "top",
          period: "5th",
          away_score: 3,
          home_score: 4,
        },
        {
          play: "Cabrera homered to right center (381 feet).",
          inning: "top",
          period: "6th",
          away_score: 4,
          home_score: 4,
        },
        {
          play: "Verdugo hit sacrifice fly to left, Judge scored.",
          inning: "top",
          period: "7th",
          away_score: 5,
          home_score: 4,
        },
      ],
    },
    {
      id: 2269,
      home_team_name: "Houston Astros",
      away_team_name: "New York Yankees",
      home_team: {
        id: 11,
        slug: "houston-astros",
        abbreviation: "HOU",
        display_name: "Houston Astros",
        short_display_name: "Astros",
        name: "Astros",
        location: "Houston",
        league: "American",
        division: "West",
      },
      away_team: {
        id: 19,
        slug: "new-york-yankees",
        abbreviation: "NYY",
        display_name: "New York Yankees",
        short_display_name: "Yankees",
        name: "Yankees",
        location: "New York",
        league: "American",
        division: "East",
      },
      season: 2024,
      postseason: false,
      date: "2024-03-30T00:10:00.000Z",
      home_team_data: {
        hits: 7,
        runs: 1,
        errors: 2,
        inning_scores: [1, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      away_team_data: {
        hits: 12,
        runs: 7,
        errors: 0,
        inning_scores: [0, 0, 0, 0, 0, 0, 2, 4, 1],
      },
      venue: "Minute Maid Park",
      attendance: 41583,
      conference_play: false,
      status: "STATUS_FINAL",
      period: 9,
      clock: 0,
      display_clock: "0:00",
      scoring_summary: [
        {
          play: "Bregman singled to center, Altuve scored.",
          inning: "bottom",
          period: "1st",
          away_score: 0,
          home_score: 1,
        },
        {
          play: "Cabrera singled to left, Volpe scored, Wells to second.",
          inning: "top",
          period: "7th",
          away_score: 1,
          home_score: 1,
        },
        {
          play: "Soto walked, Wells scored, Torres to second, Cabrera to third.",
          inning: "top",
          period: "7th",
          away_score: 2,
          home_score: 1,
        },
        {
          play: "Volpe grounded into fielder's choice to shortstop, Rizzo scored on error, Verdugo safe at second on throwing error by shortstop Peña, Verdugo safe at third on error.",
          inning: "top",
          period: "8th",
          away_score: 3,
          home_score: 1,
        },
        {
          play: "Wells reached on bunt single to pitcher, Verdugo scored on throwing error by pitcher Mushinski, Wells to first on throwing error by pitcher Mushinski, Wells to second, Volpe to third.",
          inning: "top",
          period: "8th",
          away_score: 4,
          home_score: 1,
        },
        {
          play: "Cabrera singled to center, Volpe scored and Wells scored.",
          inning: "top",
          period: "8th",
          away_score: 6,
          home_score: 1,
        },
        {
          play: "Stanton homered to left center (419 feet).",
          inning: "top",
          period: "9th",
          away_score: 7,
          home_score: 1,
        },
      ],
    },
    {
      id: 2804,
      home_team_name: "Houston Astros",
      away_team_name: "New York Yankees",
      home_team: {
        id: 11,
        slug: "houston-astros",
        abbreviation: "HOU",
        display_name: "Houston Astros",
        short_display_name: "Astros",
        name: "Astros",
        location: "Houston",
        league: "American",
        division: "West",
      },
      away_team: {
        id: 19,
        slug: "new-york-yankees",
        abbreviation: "NYY",
        display_name: "New York Yankees",
        short_display_name: "Yankees",
        name: "Yankees",
        location: "New York",
        league: "American",
        division: "East",
      },
      season: 2024,
      postseason: false,
      date: "2024-03-30T23:15:00.000Z",
      home_team_data: {
        hits: 4,
        runs: 3,
        errors: 1,
        inning_scores: [0, 2, 0, 0, 1, 0, 0, 0, 0],
      },
      away_team_data: {
        hits: 8,
        runs: 5,
        errors: 3,
        inning_scores: [0, 0, 1, 0, 0, 0, 3, 1, 0],
      },
      venue: "Minute Maid Park",
      attendance: 41247,
      conference_play: false,
      status: "STATUS_FINAL",
      period: 9,
      clock: 0,
      display_clock: "0:00",
      scoring_summary: [
        {
          play: "Dubón doubled to right, Diaz scored and Peña scored.",
          inning: "bottom",
          period: "2nd",
          away_score: 0,
          home_score: 2,
        },
        {
          play: "Soto scored on error, Rizzo safe at first on fielding error by first baseman J. Abreu, Judge to second.",
          inning: "top",
          period: "3rd",
          away_score: 1,
          home_score: 2,
        },
        {
          play: "Alvarez grounded into fielder's choice to first, Caratini scored on throwing error by shortstop Volpe, Altuve out at second, Alvarez safe at third on throwing error by pitcher Stroman.",
          inning: "bottom",
          period: "5th",
          away_score: 1,
          home_score: 3,
        },
        {
          play: "Cabrera homered to right (341 feet), Wells scored.",
          inning: "top",
          period: "7th",
          away_score: 3,
          home_score: 3,
        },
        {
          play: "Soto homered to left (349 feet).",
          inning: "top",
          period: "7th",
          away_score: 4,
          home_score: 3,
        },
        {
          play: "Volpe homered to left center (375 feet).",
          inning: "top",
          period: "8th",
          away_score: 5,
          home_score: 3,
        },
      ],
    },
    {
      id: 2896,
      home_team_name: "Houston Astros",
      away_team_name: "New York Yankees",
      home_team: {
        id: 11,
        slug: "houston-astros",
        abbreviation: "HOU",
        display_name: "Houston Astros",
        short_display_name: "Astros",
        name: "Astros",
        location: "Houston",
        league: "American",
        division: "West",
      },
      away_team: {
        id: 19,
        slug: "new-york-yankees",
        abbreviation: "NYY",
        display_name: "New York Yankees",
        short_display_name: "Yankees",
        name: "Yankees",
        location: "New York",
        league: "American",
        division: "East",
      },
      season: 2024,
      postseason: false,
      date: "2024-03-31T18:10:00.000Z",
      home_team_data: {
        hits: 12,
        runs: 3,
        errors: 0,
        inning_scores: [0, 0, 1, 0, 0, 2, 0, 0, 0],
      },
      away_team_data: {
        hits: 10,
        runs: 4,
        errors: 1,
        inning_scores: [0, 1, 0, 1, 1, 0, 0, 0, 1],
      },
      venue: "Minute Maid Park",
      attendance: 36908,
      conference_play: false,
      status: "STATUS_FINAL",
      period: 9,
      clock: 0,
      display_clock: "0:00",
      scoring_summary: [
        {
          play: "Trevino reached on infield single to first, Stanton scored.",
          inning: "top",
          period: "2nd",
          away_score: 1,
          home_score: 0,
        },
        {
          play: "Altuve homered to left (341 feet).",
          inning: "bottom",
          period: "3rd",
          away_score: 1,
          home_score: 1,
        },
        {
          play: "Berti singled to right, Rizzo scored.",
          inning: "top",
          period: "4th",
          away_score: 2,
          home_score: 1,
        },
        {
          play: "Judge hit sacrifice fly to center, Torres scored.",
          inning: "top",
          period: "5th",
          away_score: 3,
          home_score: 1,
        },
        {
          play: "Tucker doubled to right, Altuve scored.",
          inning: "bottom",
          period: "6th",
          away_score: 3,
          home_score: 2,
        },
        {
          play: "Diaz singled to center, Tucker scored.",
          inning: "bottom",
          period: "6th",
          away_score: 3,
          home_score: 3,
        },
        {
          play: "Soto singled to left, Torres scored.",
          inning: "top",
          period: "9th",
          away_score: 4,
          home_score: 3,
        },
      ],
    },
  ],
  meta: {
    next_cursor: 9339,
    per_page: 25,
  },
};
