import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, extendTheme, ColorModeScript } from "@chakra-ui/react";
import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: "563551ed-abc6-4d0b-9e91-a9ec0c15fd3c",
  clientToken: "pubb6133cd45b31e48bc60467cfae8f5f30",
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "datadoghq.com",
  service: "balldontlie-app",
  env: "prod",
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

const theme = extendTheme({
  initialColorMode: "dark",
  useSystemColorMode: false,
});

ReactDOM.render(
  <>
    <ColorModeScript initialColorMode={"dark"} />
    <React.StrictMode>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </BrowserRouter>
    </React.StrictMode>
  </>,
  document.getElementById("root")
);
