import {
  Button,
  Center,
  Heading,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { resetPassword } from "./api";

export function ResetPassword() {
  const toast = useToast();
  const emailRef = React.useRef<any>(null);
  const [loading, setLoading] = React.useState(false);

  const onSubmit = React.useCallback(async () => {
    const email = emailRef.current?.value?.toLowerCase();

    if (email) {
      setLoading(true);
      try {
        await resetPassword({ email });
        toast({
          title: "Reset instructions were sent to your email",
          status: "success",
          isClosable: true,
        });
      } catch {
        toast({
          title: "Incorrect email or password",
          status: "error",
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Email required",
        status: "error",
        isClosable: true,
      });
    }
    setLoading(false);
  }, [emailRef]);

  React.useEffect(() => {
    const listener = (e: any) => {
      if (e.key?.toLowerCase() === "enter") {
        onSubmit();
      }
    };
    window.removeEventListener("keydown", listener);
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [onSubmit]);

  return (
    <Center w="100%" h="100%">
      <VStack w={["90%", "50%"]} spacing={4}>
        <Heading>FORGOT YOUR PASSWORD?</Heading>
        <Input w="100%" ref={emailRef} placeholder="email" />
        <Button
          colorScheme="teal"
          w="100%"
          onClick={onSubmit}
          isLoading={loading}
        >
          Submit
        </Button>
      </VStack>
    </Center>
  );
}
