import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";

export const NFLPricing = () => {
  return (
    <TableContainer>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Endpoint</Th>
            <Th>FREE</Th>
            <Th>ALL-STAR ($9.99)</Th>
            <Th>GOAT ($39.99)</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Players</Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>

            <Td textAlign="center">
              <CheckIcon />
            </Td>
          </Tr>
          <Tr>
            <Td>Teams</Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>

            <Td textAlign="center">
              <CheckIcon />
            </Td>
          </Tr>
          <Tr>
            <Td>Games + Live Scores</Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
          </Tr>
          <Tr>
            <Td>Player Injuries</Td>
            <Td textAlign="center">
              <CloseIcon />
            </Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
          </Tr>
          <Tr>
            <Td>Active Players</Td>
            <Td textAlign="center">
              <CloseIcon />
            </Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
          </Tr>
          <Tr>
            <Td>Team Standings</Td>
            <Td textAlign="center">
              <CloseIcon />
            </Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
          </Tr>
          <Tr>
            <Td>Player Stats</Td>
            <Td textAlign="center">
              <CloseIcon />
            </Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
          </Tr>
          <Tr>
            <Td>Player Season Stats</Td>
            <Td textAlign="center">
              <CloseIcon />
            </Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
          </Tr>
          <Tr>
            <Td>Advanced Rushing Stats</Td>
            <Td textAlign="center">
              <CloseIcon />
            </Td>
            <Td textAlign="center">
              <CloseIcon />
            </Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
          </Tr>
          <Tr>
            <Td>Advanced Receiving Stats</Td>
            <Td textAlign="center">
              <CloseIcon />
            </Td>
            <Td textAlign="center">
              <CloseIcon />
            </Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
          </Tr>
          <Tr>
            <Td>Advanced Passing Stats</Td>
            <Td textAlign="center">
              <CloseIcon />
            </Td>
            <Td textAlign="center">
              <CloseIcon />
            </Td>
            <Td textAlign="center">
              <CheckIcon />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
