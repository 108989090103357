import { Text, Box, Heading, Link } from "@chakra-ui/react";

export const AboutUs = () => {
  return (
    <Box padding={12} width="50%">
      <Heading size="2xl" mb={4}>
        About Us
      </Heading>
      <Text>
        At BALLDONTLIE, we’re a small team of passionate engineers who share a
        deep love for sports and technology. For years, we’ve lived at the
        intersection of the two, building and consuming APIs that power
        everything from personal projects to enterprise-grade applications.
      </Text>
      <br />
      <Text>
        But we’ve often found ourselves frustrated by APIs that didn’t quite
        meet the mark—whether they were hard to use, poorly documented, or just
        didn’t deliver on their promises. So, we set out to create the kind of
        API that we would love to use: one that’s reliable, intuitive, and built
        with developers in mind.
      </Text>
      <br />
      <Text>
        Our decades of experience with APIs and software engineering have taught
        us what makes a great API. That’s why every endpoint, every piece of
        documentation, and every ounce of data in our sports API is crafted with
        care. We aim to empower developers to create amazing applications and
        unlock the full potential of sports data.
      </Text>
      <br />
      <Text>
        Whether you’re building the next great fantasy sports app, analyzing
        player performance, or just tinkering with sports data for fun, we’ve
        got you covered. Because at the end of the day, we’re not just here to
        build an API. We’re here to support a community of developers who love
        sports as much as we do.
      </Text>
      <br />
      <Link href="https://discord.gg/cQJhfTPn8j" isExternal>
        Join us on Discord
      </Link>
      <Text>or</Text>
      <Text>Email us at hello@balldontlie.io</Text>
    </Box>
  );
};
