import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { getSession } from "./api";
import "./App.css";
import { AppContext } from "./context";
import { SignIn } from "./SignIn";
import { SignUp } from "./SignUp";
import {
  Text,
  Box,
  Link,
  VStack,
  useColorMode,
  HStack,
} from "@chakra-ui/react";
import { User } from "./types";
import { Home } from "./Home";
import { NavBar } from "./NavBar";
import { Landing } from "./Landing";
import { ResetPassword } from "./ResetPassword";
import { ChangePassword } from "./ChangePassword";
import { Terms } from "./Terms";
import { AboutUs } from "./AboutUs";

function App() {
  const [user, setUser] = React.useState<null | User | undefined>();
  const { toggleColorMode, colorMode } = useColorMode();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (colorMode === "light") {
      toggleColorMode();
    }
  }, [colorMode]);
  React.useEffect(() => {
    const fetchSession = async () => {
      try {
        const data = await getSession();
        if (data) {
          setUser(data);
        } else {
          setUser(null);
        }
      } catch (e) {
        setUser(null);
      }
    };

    fetchSession();
  }, []);

  React.useEffect(() => {
    document.title = "BALLDONTLIE API";
  }, []);

  if (user === undefined) {
    // Loading session
    return null;
  }

  return (
    <AppContext.Provider value={{ user: user as any, setUser }}>
      <VStack w="100%" h="100%">
        <NavBar />
        <div style={{ flex: 1, width: "100%", padding: 12 }}>
          <Routes>
            <Route path="/" element={user?.email ? <Home /> : <Landing />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/about" element={<AboutUs />} />
          </Routes>
        </div>
        {!user?.email && (
          <HStack
            background="var(--chakra-colors-gray-700)"
            w="100%"
            style={{ marginTop: 72, padding: 24 }}
            justify="center"
          >
            <Link onClick={() => navigate("/about")}>About Us</Link>
            <Text>-</Text>
            <Link onClick={() => navigate("/terms")}>Terms of Service</Link>
            <Text>-</Text>
            <Link href="https://nba.balldontlie.io" isExternal>
              NBA API
            </Link>
            <Text>-</Text>
            <Link href="https://nfl.balldontlie.io" isExternal>
              NFL API
            </Link>
            <Text>-</Text>
            <Link href="https://mlb.balldontlie.io" isExternal>
              MLB API
            </Link>
            <Text>-</Text>
            <Text>BALLDONTLIE LLC, 2024</Text>
            <Text>-</Text>
            <Text>hello@balldontlie.io</Text>
          </HStack>
        )}
      </VStack>
    </AppContext.Provider>
  );
}

export default App;
