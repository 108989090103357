import React from "react";
import { changePassword } from "./api";
import {
  Button,
  Center,
  Heading,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export function ChangePassword() {
  const navigate = useNavigate();
  const toast = useToast();

  const query = new URLSearchParams(window.location.search);
  const token = query.get("token");

  const passwordRef = React.useRef<any>(null);
  const passwordConfRef = React.useRef<any>(null);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(async () => {
    const password = passwordRef.current?.value;
    const passwordConf = passwordConfRef.current?.value;

    if (!token) {
      toast({
        title: "Unexpected error. Please reach out to support.",
        status: "error",
        isClosable: true,
      });
      return;
    }

    if (password !== passwordConf) {
      toast({
        title: "Passwords don't match",
        status: "error",
        isClosable: true,
      });
    } else if (password && passwordConf) {
      try {
        setLoading(true);
        await changePassword({ token, password });
        toast({
          title: "Password changed. Please login with your new password.",
          status: "success",
          isClosable: true,
        });
        navigate("/signin");
      } catch {
        setLoading(false);
        toast({
          title: "Unexpected error",
          status: "error",
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "All fields are required",
        status: "error",
        isClosable: true,
      });
    }
  }, [passwordRef, passwordConfRef]);

  React.useEffect(() => {
    const listener = (e: any) => {
      if (e.key?.toLowerCase() === "enter") {
        onSubmit();
      }
    };
    window.removeEventListener("keydown", listener);
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [onSubmit]);

  return (
    <Center w="100%" h="100%">
      <VStack w={["90%", "50%"]} spacing={4}>
        <Heading>CHANGE PASSWORD</Heading>
        <Input type="password" ref={passwordRef} placeholder="password" />
        <Input
          type="password"
          ref={passwordConfRef}
          placeholder="confirm password"
        />
        <Button
          w="100%"
          colorScheme="teal"
          onClick={onSubmit}
          isLoading={isLoading}
        >
          Submit
        </Button>
      </VStack>
    </Center>
  );
}
