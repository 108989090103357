import {
  Box,
  Button,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
} from "@chakra-ui/react";
import { useAppContext } from "./hooks";
import React from "react";
import { signOut } from "./api";
import { useNavigate, useLocation } from "react-router-dom";

import { ReactComponent as Logo } from "./assets/logo-no-ball.svg";
import { ChevronDownIcon } from "@chakra-ui/icons";

export const NavBar = () => {
  const { user, setUser } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const shouldShowLogo =
    location.pathname.includes("signup") ||
    location.pathname.includes("signin") ||
    location.pathname.includes("terms") ||
    location.pathname.includes("about") ||
    user?.email;

  const onSignOut = React.useCallback(async () => {
    await signOut();
    setUser(null);
  }, []);

  return (
    <VStack w="100%">
      <HStack
        w="100%"
        p={3}
        justifyContent={shouldShowLogo ? "space-between" : "flex-end"}
        flexDir="row"
      >
        {shouldShowLogo ? (
          <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
            <Logo width="150px" height="50px" />
          </div>
        ) : null}
        <Box>
          <Menu>
            <MenuButton
              colorScheme="teal"
              variant="outline"
              mr={4}
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              View Docs
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => window.open("https://docs.balldontlie.io")}
              >
                NBA
              </MenuItem>
              <MenuItem
                onClick={() => window.open("https://nfl.balldontlie.io")}
              >
                NFL
              </MenuItem>
              <MenuItem
                onClick={() => window.open("https://mlb.balldontlie.io")}
              >
                MLB
              </MenuItem>
            </MenuList>
          </Menu>
          {user?.email ? (
            <Button onClick={onSignOut}>Log Out</Button>
          ) : (
            <>
              <Button
                colorScheme="teal"
                mr={4}
                onClick={() => navigate("/signup")}
              >
                Sign Up
              </Button>
              <Button variant="outline" onClick={() => navigate("/signin")}>
                Sign In
              </Button>
            </>
          )}
        </Box>
      </HStack>
      <Divider />
    </VStack>
  );
};
