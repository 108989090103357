import { Box, Heading } from "@chakra-ui/react";

export const Terms = () => {
  return (
    <Box padding={12} width="75%">
      <Heading size="2xl" mb={4}>
        Terms of Service
      </Heading>
      Welcome to BALLDONTLIE LLC (“Company”, “we”, “our”, or “us”). These Terms
      of Service (“Terms”) govern your access to and use of our sports data API
      services (the “Services”). By accessing or using our Services, you agree
      to be bound by these Terms. If you do not agree, you may not access or use
      the Services.
      <Heading my={8}>1. Description of Services</Heading>
      BALLDONTLIE LLC provides sports data for the NFL, NBA, and MLB through an
      API. We are not affiliated with, endorsed by, or connected to the National
      Football League, National Basketball Association, Major League Baseball,
      or any of their respective teams, subsidiaries, or affiliates.
      <Heading my={8}>2. Account Registration and Security</Heading>
      To access our Services, you must create an account and provide accurate
      and complete information. You are responsible for maintaining the
      confidentiality of your account credentials and are fully responsible for
      all activities that occur under your account.
      <Heading my={8}>3. Use of the Services</Heading>
      You agree to: Use the Services solely for lawful purposes and in
      accordance with these Terms. Not use the Services in a way that could
      harm, disable, overburden, or impair our systems.
      <Heading my={8}>4. Data Usage Restrictions</Heading>
      The sports data provided through the Services must not be: Used in
      connection with any illegal or unethical activities. Used in a way that
      suggests endorsement by or affiliation with the NFL, NBA, or MLB.
      <Heading my={8}>5. Fees and Payment</Heading>
      Access to certain features or levels of the Services may require payment
      of fees. All fees are non-refundable unless otherwise specified.
      <Heading my={8}>6. Disclaimers</Heading>
      The Services are provided "as is" and "as available," without warranties
      of any kind, either express or implied. We do not guarantee the accuracy,
      completeness, or timeliness of the data provided.
      <Heading my={8}>7. Limitation of Liability</Heading>
      To the maximum extent permitted by law, BALLDONTLIE LLC and its officers,
      directors, employees, and agents shall not be liable for any indirect,
      incidental, special, consequential, or punitive damages arising out of or
      relating to your use of the Services.
      <Heading my={8}>8. Indemnification</Heading>
      You agree to indemnify, defend, and hold harmless BALLDONTLIE LLC from any
      claims, liabilities, damages, losses, or expenses, including reasonable
      attorneys' fees, arising out of or related to your use of the Services or
      violation of these Terms.
      <Heading my={8}>9. Termination</Heading>
      We may suspend or terminate your access to the Services at our sole
      discretion, without prior notice, if you violate these Terms or if we
      suspect misuse of the data.
      <Heading my={8}>10. Governing Law and Dispute Resolution</Heading>
      These Terms are governed by the laws of the State of New York, without
      regard to its conflict of laws principles. Any disputes arising under
      these Terms will be resolved exclusively in the state or federal courts
      located in New York, NY.
      <Heading my={8}>11. Changes to These Terms</Heading>
      We reserve the right to update or modify these Terms at any time.
      Continued use of the Services after changes are posted constitutes your
      acceptance of the revised Terms.
      <Heading my={8}>12. Contact Us</Heading>
      If you have questions about these Terms, please contact us at
      hello@balldontlie.io. By using our Services, you acknowledge that you have
      read, understood, and agreed to these Terms of Service.
    </Box>
  );
};
