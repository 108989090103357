import {
  Button,
  Center,
  Heading,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { signIn } from "./api";
import { useAppContext } from "./hooks";
import { useNavigate } from "react-router-dom";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";

export function SignIn() {
  const navigate = useNavigate();
  const toast = useToast();
  const { setUser } = useAppContext();
  const emailRef = React.useRef<any>(null);
  const passwordRef = React.useRef<any>(null);

  const onSubmit = React.useCallback(async () => {
    const email = emailRef.current?.value?.toLowerCase();
    const password = passwordRef.current?.value;

    if (email && password) {
      try {
        const res = await signIn({ email, password });
        setUser(res);
        navigate("/");
      } catch {
        toast({
          title: "Incorrect email or password",
          status: "error",
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "All fields are required",
        status: "error",
        isClosable: true,
      });
    }
  }, [emailRef, passwordRef, setUser]);

  React.useEffect(() => {
    const listener = (e: any) => {
      if (e.key?.toLowerCase() === "enter") {
        onSubmit();
      }
    };
    window.removeEventListener("keydown", listener);
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [onSubmit]);

  return (
    <Center w="100%" h="100%">
      <VStack w={["90%", "50%"]} spacing={4}>
        <Heading>WELCOME BACK</Heading>
        <Input w="100%" ref={emailRef} placeholder="email" />
        <Input ref={passwordRef} type="password" placeholder="password" />
        <Button colorScheme="teal" w="100%" onClick={onSubmit}>
          Submit
        </Button>
        <ChakraLink as={ReactRouterLink} to="/resetpassword" color="white">
          Forgot Password?
        </ChakraLink>
      </VStack>
    </Center>
  );
}
